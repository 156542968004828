<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('fair_report.ledger_report') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:searchBody>
          <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
            <b-form id="form" @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" autocomplete="off">
            <b-row>
              <b-col sm="4">
                <ValidationProvider name="Year" vid="year" rules="required|min_value:1" v-slot="{ errors }">
                  <b-form-group
                    label-for="year">
                    <template v-slot:label>
                      {{ $t('globalTrans.year') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      id="year"
                      :options="yearList"
                      v-model="search.year"
                      :state="errors[0] ? false : (valid ? true : null)"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
                <b-col sm="4">
                  <b-form-group
                    label-for="fair_id"
                  >
                    <template v-slot:label>
                      {{ $t('tradeFairConfig.fair_name') }}
                    </template>
                    <b-form-select
                      disabled
                      plain
                      id="fair_id"
                      :options="dhakaFairList"
                      v-model="search.fair_id"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </b-col>
              <b-col sm="4">
                  <b-form-group
                    label-for="stall_category"
                  >
                    <template v-slot:label>
                      {{ $t('fair_report.space_category') }}
                    </template>
                    <b-form-select
                      plain
                      id="stall_category"
                      :options="stallCategoryList"
                      v-model="search.stall_category"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
              </b-col>
              <b-col sm="4">
              <ValidationProvider name="Circular type" vid="circular_type" rules="required|min_value:1" v-slot="{ errors }">
              <b-form-group
                label-for="circular_type">
                <template v-slot:label>
                {{ $t('ditfConfig.circular') + ' ' + $t('ditfConfig.type')}} <span class="text-danger">*</span>
                </template>
                <b-form-select
                  plain
                  id="circular_type"
                  :options="circularTypeList"
                  v-model="search.circular_type"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                  <template v-slot:first>
                    <b-form-select-option :value=0 disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
              </b-col>
              <b-col sm="4">
                <b-form-group
                  label-for="payment_type"
                >
                  <template v-slot:label>
                    {{ $t('fair_report.payment_type') }}
                  </template>
                  <b-form-select
                    plain
                    id="payment_type"
                    :options="paymentTypeList"
                    v-model="search.payment_type"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col sm="4" class="mt-3">
                <b-button type="submit" size="sm" variant="primary">
                  <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                </b-button>
              </b-col>
        </b-row>
          </b-form>
          </ValidationObserver>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">
          {{ $t('fair_report.ledger_report') }} {{ $t('globalTrans.list') }}
        </h4>
      </template>
      <template v-slot:headerAction>
        <b-button v-if="isResult && ledgerList" variant="primary" size="sm" @click="pdfExport" class="float-right">
            {{  $t('globalTrans.export_pdf') }}
        </b-button>
      </template>
      <template v-slot:body>
        <b-overlay :show="customloading">
          <list-report-head :base-url="baseUrl" uri="/itf-configuration/report-head/detail" :org-id="2">
            <template v-slot:projectNameSlot>
              {{ }}
            </template>
            {{ $t('fair_report.ledger_report') }}
          </list-report-head>
          <b-row>
            <b-col md="12" class="text-left">
              <strong>{{ parseInt(search.fair_id) ? getSearchFairName(search.fair_id) : $t('globalTrans.all') }}</strong>
            </b-col>
            <b-col md="6" class="text-left">
              <span>{{ $t('fair_report.space_category') }}</span>:
              <strong>{{ parseInt(search.stall_category) ? getCategoryName(search.stall_category) : $t('globalTrans.all') }}</strong>
            </b-col>
            <b-col md="4" class="text-right">
               {{ $t('fair_report.payment_type') }}:
              <strong>{{ search.payment_type ? searchHeaderData.payment : $t('globalTrans.all') }}</strong>
            </b-col>
          </b-row>
          <b-row>
            <b-overlay>
              <b-row>
                <b-col lg="12" sm="12">
                  <table style="width:100%;">
                    <thead>
                      <tr>
                        <th>{{ $t('globalTrans.sl_no') }}</th>
                        <th>{{ $t('fair_report.org_name') }}</th>
                        <th>{{ $t('globalTrans.mobile_no') }}</th>
                        <th>{{ $t('fair_report.stall_cat') }}</th>
                        <th v-if="search.circular_type === 1">{{ $t('fair_report.stall_no') }}</th>
                        <th v-if="search.payment_type === 0 || search.payment_type === 1"> {{ $t('fair_report.bidding_price') }}</th>
                        <th v-if="search.payment_type !== 1" >{{ $t('fair_report.payment_total') }}</th>
                      </tr>
                    </thead>
                    <tbody v-if="isResult && ledgerList.length !== undefined && search.circular_type === 1">
                      <span hidden>{{ $si = 1 }}</span>
                        <template v-for="(item, index) in ledgerList">
                          <template v-for="(el, index2) in item.stall_pavilion_booked">
                            <tr :key="index + 'hi' + index2">
                              <td v-if="index2 === 0" :rowspan="item.stall_pavilion_booked.length" class="text-center"> {{$n($si++)}}</td>
                              <td v-if="index2 === 0" :rowspan="item.stall_pavilion_booked.length"> {{ currentLocale === 'bn' ? item.comp_name_bn : item.comp_name_en }}</td>
                              <td v-if="index2 === 0" :rowspan="item.stall_pavilion_booked.length"> {{ item.mobile_no | mobileNumber }} </td>
                              <td> {{ currentLocale === 'bn' ? el.stall_category.cat_name_bn : el.stall_category.cat_name_en }}</td>
                              <td class="text-left"> {{ EngBangNum(el.stall.stall_no.toString()) }}</td>
                              <td class="text-right" v-if="search.payment_type === 0 || search.payment_type === 1">
                                <span>{{ $n(el.quoted_price, { minimumFractionDigits: 2 }) }} {{ $t('globalTrans.tk') }}</span><br>
                              </td>
                              <td class="text-right" v-if="search.payment_type !== 1">
                                <span v-if="search.payment_type === 2 || search.payment_type === 0"> {{ $t('fair_report.processing') + ' : ' + $n(el.stall_category.processing_fee, { minimumFractionDigits: 2 }) }} {{ $t('globalTrans.tk') }} </span><br>
                                <span v-if="search.payment_type === 3 || search.payment_type === 0"> {{ $t('fair_report.sec_money') + ' : ' + $n(((el.quoted_price/100) * config.percentage), { minimumFractionDigits: 2 }) }} {{ $t('globalTrans.tk') }} </span><br>
                                <span v-if="search.payment_type === 4 || search.payment_type === 0 && item.app_stall_payment.length > 0">
                                    <span v-if="search.payment_type === 4">{{$t('externalTradeFair.payable_amount') + ' : ' + $n(getPayableAmount(item.app_stall_payment, el))}} {{ $t('globalTrans.tk') }}<br></span>
                                     <!-- for database realted issue here vat and tax are change  -->
                                    <span v-if="search.payment_type === 4">{{$t('globalTrans.vat') + ' : ' + $n(getVatTax(item.app_stall_payment, el)?.tax)}} {{ $t('globalTrans.tk') }}<br></span>
                                      <span v-if="search.payment_type === 4">{{$t('globalTrans.tax') + ' : ' + $n(getVatTax(item.app_stall_payment, el)?.vat)}} {{ $t('globalTrans.tk') }}<br></span>
                                       <!-- for database realted issue here vat and tax are change  -->
                                      <span v-if="search.payment_type === 4">{{$t('globalTrans.total') + ' : ' + $n(getStallMoney(item.app_stall_payment, el))}} {{ $t('globalTrans.tk') }}<br></span>
                                      <span v-if="search.payment_type === 0">
                                        {{ $t('fair_report.allotment_fee') + ' : ' + $n(getStallMoney(item.app_stall_payment, el)) }} {{ $t('globalTrans.tk') }}
                                      </span>
                                </span>
                              </td>
                            </tr>
                          </template>
                        </template>
                        <template v-if="ledgerList.length !== 0">
                          <!-- <tr v-if="search.payment_type === 0 || search.payment_type === 1">
                            <td :colspan="search.payment_type !== 1 ? 6 : 5" class="text-right"> <b> {{ $t('fair_report.total_bidding') }} </b> </td>
                            <td colspan="1" class="text-left"> {{ $n(total_bidding, { minimumFractionDigits: 2 }) }} {{ $t('globalTrans.tk') }}</td>
                          </tr> -->
                          <tr v-if="search.payment_type === 0 || search.payment_type === 2">
                            <td :colspan="search.payment_type !== 0 ? 5 : 6" class="text-right"> <b> {{ $t('fair_report.total_processing') }}</b> </td>
                            <td colspan="1" class="text-right"> {{ $n(total_processing, { minimumFractionDigits: 2 }) }} {{ $t('globalTrans.tk') }}</td>
                          </tr>
                          <tr v-if="search.payment_type === 0 || search.payment_type === 3">
                            <td :colspan="search.payment_type !== 0 ? 5 : 6" class="text-right"> <b> {{ $t('fair_report.total_security') }}</b> </td>
                            <td colspan="1" class="text-right"> {{ $n(total_security_money, { minimumFractionDigits: 2 }) }} {{ $t('globalTrans.tk') }}</td>
                          </tr>
                          <tr v-if="search.payment_type === 0 || search.payment_type === 4">
                            <td :colspan="search.payment_type !== 0 ? 5 : 6" class="text-right"> <b> {{ $t('fair_report.total_stall_allotment') }} {{ $t('fair_report.without_vat_tax') }}</b> </td>
                            <td colspan="1" class="text-right"> {{ $n(total_stall_money, { minimumFractionDigits: 2 }) }} {{ $t('globalTrans.tk') }}</td>
                          </tr>
                          <!-- for database realted issue here vat and tax are change  -->
                          <tr v-if="search.payment_type === 0 || search.payment_type === 4">
                            <td :colspan="search.payment_type !== 0 ? 5 : 6" class="text-right"> <b> {{ $t('fair_report.total_vat') }}</b> </td>
                            <td colspan="1" class="text-right"> {{ $n(total_tax, { minimumFractionDigits: 2 }) }} {{ $t('globalTrans.tk') }}</td>
                          </tr>
                          <tr v-if="search.payment_type === 0 || search.payment_type === 4">
                            <td :colspan="search.payment_type !== 0 ? 5 : 6" class="text-right"> <b> {{ $t('fair_report.total_tax') }}</b> </td>
                            <td colspan="1" class="text-right"> {{ $n(total_vat, { minimumFractionDigits: 2 }) }} {{ $t('globalTrans.tk') }}</td>
                          </tr>
                            <!-- for database realted issue here vat and tax are change  -->
                          <tr v-if="search.payment_type === 0 || search.payment_type === 4">
                            <td :colspan="search.payment_type !== 0 ? 5 : 6" class="text-right"> <b> {{ $t('globalTrans.grand_total') }}</b> </td>
                            <td colspan="1" class="text-right"> {{ $n(grand_total, { minimumFractionDigits: 2 }) }} {{ $t('globalTrans.tk') }}</td>
                          </tr>
                        </template>
                    </tbody>
                    <tbody v-if="isResult && ledgerList.length !== undefined && search.circular_type === 2">
                      <span hidden>{{ $si = 1 }}</span>
                        <template v-for="(item, index) in ledgerList">
                          <template v-for="(el, index2) in item.stall_other_booked">
                            <tr :key="index + 'hi' + index2">
                              <td v-if="index2 === 0" :rowspan="item.stall_other_booked.length" class="text-center"> {{$n($si++)}}</td>
                              <td v-if="index2 === 0" :rowspan="item.stall_other_booked.length"> {{ currentLocale === 'bn' ? item.comp_name_bn : item.comp_name_en }}</td>
                              <td v-if="index2 === 0" :rowspan="item.stall_other_booked.length"> {{ item.mobile_no | mobileNumber }} </td>
                              <td> {{ currentLocale === 'bn' ? el.stall_category.cat_name_bn : el.stall_category.cat_name_en }}</td>
                              <td v-if="search.circular_type === 1"> {{ currentLocale === 'bn' ? el.stall_category.stall_id : el.stall_category.stall_id }}</td>
                              <td class="text-right" v-if="search.payment_type === 0 || search.payment_type === 1">
                                <span> {{ $n(el.quoted_price, { minimumFractionDigits: 2 }) }} {{ $t('globalTrans.tk') }}</span><br>
                              </td>
                              <td class="text-right" v-if="search.payment_type !== 1">
                                <span v-if="search.payment_type === 2 || search.payment_type === 0"> {{ $t('fair_report.processing') + ' : ' + $n(el.stall_category.processing_fee, { minimumFractionDigits: 2 }) }} {{ $t('globalTrans.tk') }}</span>
                              </td>
                            </tr>
                          </template>
                        </template>
                        <template v-if="ledgerList.length !== 0">
                        <tr v-if="search.payment_type === 1 || search.payment_type === 0">
                          <td :colspan="search.payment_type !== 0 ? 4 : 5" class="text-right"> <b> {{ $t('fair_report.total_bidding') }} {{ $t('globalTrans.tk') }} </b> </td>
                          <td colspan="1" class="text-right"> {{ $n(total_bidding, { minimumFractionDigits: 2 }) }} {{ $t('globalTrans.tk') }}</td>
                        </tr>
                        <tr v-if="search.payment_type === 2 || search.payment_type === 0">
                          <td :colspan="search.payment_type !== 0 ? 4 : 5" class="text-right"> <b> {{ $t('fair_report.total_processing') }} {{ $t('globalTrans.tk') }}</b> </td>
                          <td colspan="1" class="text-right"> {{ $n(total_processing, { minimumFractionDigits: 2 }) }} {{ $t('globalTrans.tk') }}</td>
                        </tr>
                        </template>
                    </tbody>
                    <tbody v-if="ledgerList.length === 0">
                      <tr v-if="search.circular_type === 1">
                        <td :colspan="search.payment_type === 0 || search.payment_type === 1 ? 7 : 6" class="text-center"> {{ $t('fair_report.not_found') }} </td>
                      </tr>
                      <tr v-else>
                        <td colspan="6" class="text-center"> {{ $t('fair_report.not_found') }} </td>
                      </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
            </b-overlay>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
  </div>
</template>
<script>
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import { ditfLedgerReportInfo } from '../../../api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import ModalBaseMasterList from '@/mixins/list'
export default {
  mixins: [ModalBaseMasterList],
  components: {
    ListReportHead
  },
  data () {
    return {
      baseUrl: internationalTradeFairServiceBaseUrl,
      valid: null,
      sortBy: '',
      customloading: false,
      isResult: false,
      ledgerList: [],
      config: [],
      fairNameList: [],
      fairSectorCusList: [],
      paid_amount: 0,
      refund_amount: 0,
      search: {
        year: 0,
        circular_type: 0,
        fair_id: 0,
        stall_category: 0,
        payment_type: 0,
        limit: 20
      },
      searchHeaderData: {
        year_bn: '',
        year_en: '',
        circular_type_bn: '',
        circular_type_en: '',
        payment: ''
      },
      paymentTypeList: [],
      dhakaFairList: [],
      total_bidding: 0,
      total_processing: 0,
      total_stall_money: 0,
      total_vat: 0,
      total_tax: 0,
      grand_total: 0,
      total_security_money: 0
    }
  },
  created () {
    const currentFiscalYear = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === this.$store.state.currentFiscalYearId)
    this.search.year = new Date(currentFiscalYear.end_date).getFullYear()
    this.search.circular_type = 1
  },
  watch: {
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    },
    'search.year': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dhakaFairList = this.dhakaFair()
        this.ledgerList = []
      }
    },
    'search.circular_type': function (newVal, oldVal) {
      this.search.payment_type = 0
      if (newVal !== oldVal) {
        this.ledgerList = []
        this.getPaymentFee()
      }
    },
    'search.payment_type': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.ledgerList = []
        this.getSearchParametter()
      }
    },
    currentLocale: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getPaymentFee()
        this.getSearchParametter()
      }
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    stallCategoryList () {
      return this.$store.state.TradeFairService.ditfCommonObj.stallCategoryList.map(item => {
        return Object.assign({}, item, { text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn })
      })
    },
    pavillionTypeFee () {
      return [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'বিডিং ফি' : 'Bidding Fee'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'প্রসেসিং ফি' : 'Processiong Fee'
        },
        {
          value: 3,
          text: this.$i18n.locale === 'bn' ? 'নিরাপত্তা জামানত' : 'Security Fee'
        },
        {
          value: 4,
          text: this.$i18n.locale === 'bn' ? 'বরাদ্দ ফি' : 'Allotment Fee'
        }
      ]
    },
    otherTypeFee () {
      return [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'বিডিং ফি' : 'Bidding Fee'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'প্রসেসিং ফি' : 'Processiong Fee'
        }
      ]
    },
    yearList () {
      return this.$store.state.commonObj.yearList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item, text: this.EngBangNum(item.toString()) }
        } else {
          return { value: item, text: item }
        }
      })
    },
    circularTypeList () {
      return this.$store.state.TradeFairService.ditfCommonObj.circularTypeList.map(item => {
        return Object.assign({}, item, { text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn })
      })
    }
  },
  methods: {
    getCategoryName (id) {
      const companyType = this.stallCategoryList.find(obj => obj.value === parseInt(id))
      if (typeof companyType !== 'undefined') {
        return this.$i18n.locale === 'en' ? companyType.text_en : companyType.text_bn
      } else {
        return ''
      }
    },
    dhakaFair () {
      this.search.fair_id = 0
      return this.$store.state.TradeFairService.ditfCommonObj.dhakaFairList.filter(item => item.year === this.search.year).map(item => {
        if (item !== '') {
          this.search.fair_id = item.value
        }
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    getSearchFairName (id) {
      const companyType = this.$store.state.TradeFairService.ditfCommonObj.dhakaFairList.find(obj => obj.value === parseInt(id))
      if (typeof companyType !== 'undefined') {
        return this.$i18n.locale === 'en' ? companyType.text_en : companyType.text_bn
      } else {
        return ''
      }
    },
    getPaymentFee () {
      if (this.search.circular_type === 1) {
        this.paymentTypeList = this.pavillionTypeFee
      }
      if (this.search.circular_type === 2) {
        this.paymentTypeList = this.otherTypeFee
      }
    },
    EngBangNum (n) {
      if (this.$i18n.locale === 'bn') {
        return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
      } else {
        return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
      }
    },
    getSearchParametter () {
      if (this.search.year) {
        const yearData = {
          year_bn: this.EngBangNum(this.search.year.toString()),
          year_en: this.search.year
        }
        this.searchHeaderData = Object.assign({}, this.searchHeaderData, yearData)
      }
      if (this.search.circular_type) {
        const circularTypeObj = this.$store.state.TradeFairService.ditfCommonObj.circularTypeList.find(obj => obj.value === this.search.circular_type)
        const circularTypeData = {
          circular_type_en: circularTypeObj?.text_en,
          circular_type_bn: circularTypeObj?.text_bn
        }
        this.searchHeaderData = Object.assign({}, this.searchHeaderData, circularTypeData)
      }
      if (this.search.payment_type) {
        if (this.search.circular_type === 1) {
          const paymentTypeObj = this.pavillionTypeFee.find(obj => obj.value === parseInt(this.search.payment_type))
          const paymentTypeData = {
            payment: paymentTypeObj?.text
          }
          this.searchHeaderData = Object.assign({}, this.searchHeaderData, paymentTypeData)
        }
        if (this.search.circular_type === 2) {
          const paymentTypeObj = this.otherTypeFee.find(obj => obj.value === parseInt(this.search.payment_type))
          const paymentTypeData = {
            payment: paymentTypeObj?.text
          }
          this.searchHeaderData = Object.assign({}, this.searchHeaderData, paymentTypeData)
        }
      }
    },
    getStallMoney (stallPay, el) {
       const pay = stallPay.find(item => item.stall_cat_id === el.stall_cat_id)
       if (pay !== undefined) {
        return pay.payment_amount
       } else {
        return 0
       }
    },
    getVatTax (stallPay, el) {
       const pay = stallPay.find(item => item.stall_cat_id === el.stall_cat_id)

       if (pay !== undefined) {
        return pay
       } else {
        return {}
       }
    },
    getPayableAmount (stallPay, el) {
       const pay = stallPay.find(item => item.stall_cat_id === el.stall_cat_id)
       if (pay !== undefined) {
        return parseFloat(pay.payment_amount) - (parseFloat(pay.vat) + parseFloat(pay.tax))
       } else {
        return 0
       }
    },
    getPaymentStatus (statusId) {
      const statusObj = this.paymentStatusList.find(el => el.value === parseInt(statusId))
      if (statusObj !== 'undefined') {
        return statusObj.text
      }
    },
    getFairNameList () {
      return this.fairList.filter(item => item.fiscal_year_id === this.search.fiscal_year_id).map(el => {
        return { value: el.value, text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en }
      })
    },
    getFairSectorList () {
      const fairObj = this.fairList.find(el => el.value === this.search.fair_name_id)
      const sectorObj = this.$store.state.TradeFairService.commonObj.fairSectorList.filter(el => el.value === parseInt(fairObj.fair_sector_id) || el.text_en.toLowerCase() === 'others').map(el => {
        return { value: el.value, text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en }
      })
      return sectorObj
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      this.customloading = true
      this.getSearchParametter()
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      // this.$store.dispatch('mutateCommonProperties', { customloading: true, listReload: false })
      const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, ditfLedgerReportInfo, params)
      if (result.success) {
        this.ledgerList = result.data
        this.config = result.security_money
        this.total_bidding = result.total_info.total_bidding
        this.total_processing = result.total_info.total_processing
        this.total_security_money = result.total_info.total_security_money
        this.total_stall_money = result.total_info.total_stall_money
        this.total_vat = result.total_info.total_vat
        this.total_tax = result.total_info.total_tax
        this.grand_total = result.total_info.grand_total
        this.customloading = false
        this.isResult = true
      }
    },
    async pdfExport () {
      this.customloading = true
      // this.searchHeaderData
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 2, year: this.search.year, circular_type: this.search.circular_type, fair_id: this.search.fair_id, stall_category: this.search.stall_category, payment_type: this.search.payment_type }, this.searchHeaderData)
      const result = await RestApi.getPdfData(internationalTradeFairServiceBaseUrl, ditfLedgerReportInfo, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.customloading = false
    }
  }
}
</script>
<style scoped>
  table, th, td {
    border: 1px solid black;
    padding:5px;
  }
</style>
